import {
  GetFormattedMoney,
  TicketWithFormattedSalePeriod,
  getPreliminaryInvoice as calculatePreliminaryInvoice,
  getPricingOption,
  getPricingOptionLabel,
  getTaxConfig,
  getTicketDefinitionCurrency,
  hasSeatingPlan,
  isDonationTicketDefinition,
} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {OrderedTicket, State} from '../types/state'
import {getEvent} from './event'
import {getInvoice as getInvoiceFromState} from './invoice'
import {getBestPurchasedPlan, getSelectedPaidPlan} from './paid-plans'
import {getSeatsPreliminaryInvoiceItems, getSelectedTicketsToPlaces} from './seating/places'
import {
  getPreliminaryInvoiceItems,
  getSelectedPricingOptionIdsCounts,
  getSortedSelectedTickets,
} from './selected-tickets'
import {getTicketById, getTickets as getTicketDefinitions} from './tickets'

export const getInvoice = (state: State): wix.events.ticketing.Invoice =>
  getInvoiceFromState(state) ?? getPreliminaryInvoice(state)

export const getPreliminaryInvoice = (state: State) => {
  const event = getEvent(state)
  const paidPlan = getSelectedPaidPlan(state) || getBestPurchasedPlan(state)

  const preliminaryInvoiceItems = hasSeatingPlan(event)
    ? getSeatsPreliminaryInvoiceItems(state)
    : getPreliminaryInvoiceItems(state)

  return calculatePreliminaryInvoice(
    getTicketDefinitions(state),
    preliminaryInvoiceItems,
    getTaxConfig(event) as wix.events.TaxConfig,
    paidPlan,
  )
}

export const isOrderCompleted = (order: wix.events.ticketing.Order) => Boolean(order?.snapshotId)

export const onlyFreeTicketsSelected = (order: wix.events.ticketing.Order) =>
  Number(order.invoice.grandTotal.amount) === 0

export const getOrderedTickets = (state: State, tickets: TicketWithFormattedSalePeriod[]): OrderedTicket[] => {
  const selectedTickets = getSortedSelectedTickets(state)
  const ticketsToPlaces = getSelectedTicketsToPlaces(state)

  return Object.keys(selectedTickets)
    .flatMap(ticketDefId => {
      const orderedTicket = getTicketById(tickets, ticketDefId) as OrderedTicket
      orderedTicket.orderedCount = selectedTickets[ticketDefId].quantity
      orderedTicket.priceOverride = selectedTickets[ticketDefId].donation
      orderedTicket.seats = ticketsToPlaces[ticketDefId]
      if (selectedTickets[ticketDefId].pricingOptionIds) {
        const pricingOptionIdsCount = getSelectedPricingOptionIdsCounts(
          orderedTicket,
          selectedTickets[ticketDefId].pricingOptionIds,
        )
        return pricingOptionIdsCount.map(({pricingOptionId, count}) => ({
          ...orderedTicket,
          pricingOptionId,
          orderedCount: count,
        }))
      }
      return orderedTicket
    })
    .filter(ticket => ticket.orderedCount)
}

export const hasPlacedOrder = (state: State) => Boolean(state.placedOrder.order)

export const getTickets = (state: State) => state.placedOrder.order.tickets ?? []

export const getTicketsPdf = (state: State) => state.placedOrder.order.ticketsPdf

export const getTicketQuantity = (state: State) => state.placedOrder.order.ticketsQuantity

export const isPreparingOrder = (order: wix.events.ticketing.Order) => {
  return order?.tickets?.length !== order?.ticketsQuantity
}

export const getGrandTotal = (state: State): number => Number(state.placedOrder.order.invoice.grandTotal.amount)

export const getPaymentMethod = (state: State) => state.placedOrder.order.method

export const getOrderNumber = (state: State) => state.placedOrder.order?.orderNumber

export const getNonFreeTicketsCount = (state: State) =>
  state.placedOrder.order?.invoice.items.reduce(
    (sum, item) => sum + ((Number(item.total.amount) && item.quantity) || 0),
    0,
  )

export const getSummaryTicketPrice = (ticket: OrderedTicket, t: TFunction, getFormattedMoney: GetFormattedMoney) => {
  const currency = getTicketDefinitionCurrency(ticket)
  let labeledPrice: string
  let price: number

  if (isDonationTicketDefinition(ticket)) {
    price = Number(ticket.priceOverride)
    labeledPrice = t('mobile.thanks.donation', {
      donation: getFormattedMoney({amount: ticket.priceOverride, currency}),
    })
  } else if (ticket.pricingOptionId) {
    price = Number(getPricingOption(ticket, ticket.pricingOptionId).price.amount)
    labeledPrice = getPricingOptionLabel(ticket, ticket.pricingOptionId, getFormattedMoney)
  } else {
    price = Number(ticket.price.amount)
    labeledPrice = t('mobile.thanks.price', {price: getFormattedMoney({amount: ticket.price.amount, currency})})
  }

  const total = getFormattedMoney({amount: (price * ticket.orderedCount).toString(), currency})

  return {price, labeledPrice, total}
}
